<template>
    <div class="home-container">
        <div ref="screenRef" class="app-content">
            <div class="navbar-section">
                <Navbar @dropItemTap="dropItemTapHandler" :navArr="navArr" :userInfo="userInfo"
                    @navbarItemTap="navbarItemTapHandler" />
            </div>
            <div class="home-content">
                <router-view class="home-view"></router-view>
            </div>
        </div>
        <!-- <Video></Video>
    <div class="echarts-section"></div> -->
    </div>
</template>

<script>
import Navbar from "../../components/navbar/navbar.vue";
import { loginOut } from '@/utils/api';
import { timeFormatSeconds, customPrint, throttle } from '@/utils/util';
// import Video from "../video/video.vue";
export default {
    components: {
        Navbar
    },
    data() {
        return {
            navArr: [
                {
                    title: '首页',
                    url: 'home',
                    show: true,
                    selected: true
                }, {
                    title: '尾气监测管理',
                    url: 'gas',
                    show: true,
                    selected: false
                }, {
                    title: '预警管理',
                    url: 'warning',
                    show: true,
                    selected: false
                }, {
                    title: '用户管理',
                    url: 'user',
                    show: true,
                    selected: false
                }
            ],
            userInfo: {},
            timer: null
        }
    },
    mounted() {
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
        if (this.userInfo.type == 0) {
            this.navArr[this.navArr.length - 1]['show'] = false;
        }
        // window.addEventListener("resize", this.resize());
    },
    destroyed() {
        // window.removeEventListener("resize", this.resize());
    },
    methods: {
        calcRate() {
            // console.log('变化了');
            // * 默认缩放值
            const scale = {
                width: "1",
                height: "1",
            };

            // * 设计稿尺寸（px）
            const baseWidth = 1920;
            const baseHeight = 1080;

            // * 需保持的比例（默认1.77778）
            const baseProportion = parseFloat((baseWidth / baseHeight).toFixed(5));
            // 当前宽高比
            const currentRate = parseFloat(
                (window.innerWidth / window.innerHeight).toFixed(5)
            );
            if (currentRate > baseProportion) {
                // 表示更宽
                scale.width = (
                    (window.innerHeight * baseProportion) /
                    baseWidth
                ).toFixed(5);
                scale.height = (window.innerHeight / baseHeight).toFixed(5);
                this.$refs.screenRef.style.transform = `scale(${scale.width}, ${scale.height})`;
            } else {
                // 表示更高
                scale.height = (
                    window.innerWidth /
                    baseProportion /
                    baseHeight
                ).toFixed(5);
                scale.width = (window.innerWidth / baseWidth).toFixed(5);
                this.$refs.screenRef.style.transform = `scale(${scale.width}, ${scale.height})`;
            }
        },
        resize() {
            clearTimeout(this.timer);
            this.timer = window.setTimeout(() => {
                this.calcRate();
            }, 200);
        },
        navbarItemTapHandler(url) {
            switch (url) {
                case 'home':
                    this.$router.push('/video')
                    break;
                case 'gas':
                    this.$router.push('/gas')
                    break;
                case 'warning':
                    this.$router.push('/warning')
                    break;
                case 'user':
                    this.$router.push('/user')
                    break;
                case 'ship':
                    this.$router.push('/ship')
                    break;
                case 'water':
                    this.$router.push('/monitor/water')
                    break;
                case 'law':
                    this.$router.push('/monitor/law')
                    break;
                case 'push':
                    this.$router.push('/warning/push')
                    break;
                case 'threshold':
                    this.$router.push('/warning/threshold')
                    break;
                case 'record':
                    this.$router.push('/video/record')
                    break;
                default:
                    break;
            }
            let navArr = this.navArr;
            navArr.forEach(item => {
                item.selected = false;
                if (item.url === url) {
                    item.selected = true;
                }
            })
            this.navArr = navArr;
        },
        dropItemTapHandler(e) {
            switch (e) {
                case 'quit':
                    this.loginOutAction();
                    break;
            }
        },
        loginOutAction() {
            let params = {
                userName: this.userInfo.userName
            }
            loginOut(params).then(res => {
                customPrint('退出登录登录回调:' + JSON.stringify(res));
                if (res.code == 0) {
                    localStorage.setItem('token', '');
                    localStorage.setItem('userInfo', '');
                    this.$router.push('/login');
                    // this.$Message.success('登录成功');
                } else {
                    this.$Message.error(res.msg);
                }
            })
        }
    }
};
</script>

<style lang="scss" scoped>
.home-container {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .app-content {
        // width: 1920px;
        // height: 1080px;
        height: 100%;
        width: 100%;

        .navbar-section {
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            height: 100px;
            overflow: visible;
            z-index: 9999;
        }

        .home-content {
            background-color: #040c31;
            position: fixed;
            top: 100px;
            left: 0;
            right: 0;
            bottom: 0;
        }

        .echarts-section {
            height: 780px;
        }
    }


}
</style>