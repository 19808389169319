<template>
    <div class="navbar-section">
        <div class="navbar-left">
            <!-- <img class="title-img" src="../../assets/navbar_title.png" alt=""> -->
            <p class="title">中铁长江交通设计集团有限公司</p>
            <p class="sub-title">内河船舶尾气污染物监测平台</p>
        </div>
        <div class="navbar-middle">
            <div class="navbar-content">
                <div v-for="(item, index) in navArr" :key="index" class="navbar-item">
                    <span v-show="item.show" @mouseover="mouseOverHandler(index)" @mouseleave="mouseLeaveHandler(index)"
                        @click="navbarItemAction(item.url)"
                        :class="{ 'navbar-item-title-selected': currentIndex === index }" class="navbar-item-title">{{
                        item.title
                        }}</span>
                    <template v-if="index === 1">
                        <transition name="slide-fade">
                            <div v-show="gasSubShow" @mouseleave="subMouseLeaveHandler(item.url)"
                                class="navbar-sub-section">
                                <div class="navbar-sub-content">
                                    <div @click="navbarItemAction(item.url)" class="navbar-sub-item" :key="index"
                                        v-for="(item, index) in gasArr">{{ item.title }}</div>
                                </div>
                            </div>
                        </transition>
                    </template>
                    <template v-if="index === 2">
                        <transition name="slide-fade">
                            <div v-show="warningSubShow" @mouseleave="subMouseLeaveHandler(item.url)"
                                class="navbar-sub-section">
                                <div class="navbar-sub-content">
                                    <div @click="navbarItemAction(item.url)" class="navbar-sub-item" :key="index"
                                        v-for="(item, index) in warningArr">{{ item.title }}</div>
                                </div>
                            </div>
                        </transition>
                    </template>
                </div>
            </div>
        </div>
        <div class="navbar-right">
            <Dropdown @on-click="dropItemHandler">
                <a class="username" href="javascript:void(0)">
                    {{userInfo.realName}}
                </a>
                <Icon type="ios-arrow-down"></Icon>
                <DropdownMenu slot="list">
                    <DropdownItem name="quit">退出登录</DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        navArr: {
            type: Array,
            default() {
                return [];
            }
        },
        userInfo: {
            type: Object,
            default: {}
        }
    },
    data() {
        return {
            gasArr: [
                {
                    title: '船舶信息',
                    url: 'ship'
                }, {
                    title: '船舶监测查询',
                    url: 'water'
                }, {
                    title: '船舶通行记录',
                    url: 'record'
                }
            ],
            warningArr: [
                {
                    title: '预警推送管理',
                    url: 'push'
                }, {
                    title: '预警阈值管理',
                    url: 'threshold'
                }
            ],
            gasSubShow: false,
            warningSubShow: false,
            currentIndex: 0
        }
    },
    created() {
        // this.$router.push('/video')
    },
    methods: {
        dropItemHandler(e) {
            // console.log('我点击了:' + e);
            this.$emit('dropItemTap', e);
        },
        subMouseLeaveHandler(url) {
            switch (url) {
                case 'gas':
                    this.gasSubShow = false;
                    break;
                case 'warning':
                    this.warningSubShow = false;
                    break;
            }
        },
        navbarItemAction(url) {
            if (url !== 'gas' && url !== 'warning') {
                this.$emit('navbarItemTap', url);
                this.gasSubShow = false;
                this.warningSubShow = false;
            }
        },
        mouseOverHandler(index) {

            this.currentIndex = index;
            switch (index) {
                case 1:
                    this.warningSubShow = false;
                    this.gasSubShow = true;
                    break;
                case 2:
                    this.gasSubShow = false;
                    this.warningSubShow = true;
                    break;
                default:
                    this.gasSubShow = false;
                    this.warningSubShow = false;
                    break;
            }
        },
        mouseLeaveHandler(index) {
            switch (index) {
                case 1:
                    // this.gasSubShow = false;
                    break;
                case 2:
                    // this.warningSubShow = false;
                    break;
                default:
                    break;
            }
        }
    }
}
</script>

<style lang="scss">
.navbar-section {
    height: 100px;
    color: #fff;
    display: flex;

    .navbar-left {
        width: 40%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding-left: 50px;
        // align-items: center;

        .title {
            font-size: 20px;
            margin-top: 20px;
            font-weight: 600;
            color: #45a1f1;
            // background-image: -webkit-gradient(linear, 0 0, 0 bottom, from(#fff), to(#45a1f1));
            // background-clip: text;
            // -webkit-text-fill-color: transparent;
        }

        .sub-title {
            font-size: 25px;
            font-weight: 600;
            color: #45a1f1;
            // background-image: -webkit-gradient(linear, 0 0, 0 bottom, from(#fff), to(#45a1f1));
            // background-clip: text;
            // -webkit-text-fill-color: transparent;
        }

        .title-img {
            width: 418px;
            height: 55px;
        }
    }

    .navbar-middle {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .navbar-content {
            width: 100%;
            display: flex;

            .navbar-item {
                width: 15%;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                overflow: visible;

                &:first-child {
                    margin-left: 10%;
                }

                .navbar-sub-section {
                    position: absolute;
                    left: 0;
                    top: 30px;
                    right: 0;
                    background-color: #0d1b4c;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    z-index: 9999;

                    .navbar-sub-content {
                        position: relative;

                        &::before {
                            content: "";
                            display: block;
                            position: absolute;
                            right: calc(50% - 4px);
                            top: -8px;
                            border-left: 4px solid transparent;
                            border-right: 4px solid transparent;
                            border-bottom: 8px solid #0d1b4c;
                        }
                    }

                    .navbar-sub-item {
                        height: 50px;
                        line-height: 50px;
                        font-size: 15px;
                        padding: 0 10px;
                        cursor: pointer;

                        &:hover {
                            color: #5099f7;
                        }
                    }
                }

                .navbar-item-title {
                    font-size: 15px;
                    cursor: pointer;
                }

                .navbar-item-title-selected {
                    color: #5099f7;
                }
            }
        }
    }

    .navbar-right {
        width: 10%;
        display: flex;
        justify-content: center;
        align-items: center;

        .ivu-dropdown {

            .ivu-dropdown-rel {


                .username {
                    color: #fff;
                    font-size: 15px;
                    margin-right: 5px;
                }
            }
        }
    }

    // 动画
    .slide-fade-enter-active {
        transition: all 0.2s ease-out;
    }

    .slide-fade-leave-active {
        transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
    }

    .slide-fade-enter-from,
    .slide-fade-leave-to {
        transform: translateY(-20px);
        opacity: 0;
    }
}
</style>